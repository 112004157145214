<template>
  <Container ref="target" class="disqus">
    <ClientOnly>
      <div id="disqus_thread" />
    </ClientOnly>
  </Container>
</template>

<script setup>
  import { useIntersectionObserver } from "@vueuse/core"

  const props = defineProps({
    element: {
      type: Object,
      default: () => ({}),
    },
  })

  const initialized = ref(false)
  const target = ref(null)
  const route = useRoute()

  const shortname = props.element?.ingredients?.find(
    (i) => i.role === "disqus_shortname",
  ).value

  const previewMode = route.query?.alchemy_preview_mode === "true"

  const init = () => {
    if (shortname && !previewMode) {
      window.disqus_config = function () {
        Object.assign(this.page, {
          url: window.location.href.split("?")[0],
          identifier: route ? route.path : window.location.pathname,
        })
      }
      useHead({
        script: [
          {
            async: true,
            "data-timestamp": +new Date(),
            src: `https://${shortname}.disqus.com/embed.js`,
          },
        ],
      })
      initialized.value = true
    }
  }

  useIntersectionObserver(target, ([{ isIntersecting }]) => {
    if (!initialized.value && isIntersecting) {
      init()
    }
  })
</script>

<style lang="scss" scoped>
  .disqus {
    @media print {
      display: none;
    }
  }
</style>
